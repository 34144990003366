const BOOKABLE_TYPES = [
  {
    text: 'Service',
    value: 'service',
  },
  {
    text: 'Space',
    value: 'space',
  },
]
export default BOOKABLE_TYPES
