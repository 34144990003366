<template>
  <div class="create-bookable">
    <BookableAppbar />
    <div class="ma-4">
      <BookableForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Bookable
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import BookableAppbar from '@/views/Home/Bookables/components/BookableAppbar'
import BookableForm from '@/views/Home/Bookables/components/BookableForm'

export default {
  components: {
    BookableAppbar,
    BookableForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'bookable/clearBookableDetails',
    }),
  },
}
</script>
<style lang="scss">
.create-bookable {
  //
}
</style>
