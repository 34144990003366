import ImageMixin from '@/utils/mixins/Image'

export default {
  mixins: [ImageMixin],
  props: {
    images: {
      type: Array,
      default: () => {
        return []
      },
    },

    /**
     * Max image count
     */
    maxImage: {
      type: Number,
      default: 10,
    },

    minImage: {
      type: Number,
      default: 0,
    },

    forUpdate: {
      type: Boolean,
      default: false,
    },

    canUpload: {
      type: Boolean,
      default: true,
    },

    maxWidth: {
      type: Number,
      default: 800,
    },
  },

  computed: {
    hasMinimumImage() {
      return this.minImage > 0
    },

    isFull() {
      return this.images.length === this.maxImage
    },
  },

  methods: {
    canDelete() {
      return this.forUpdate && this.hasMinimumImage
        ? this.images.length > this.minImage
        : true
    },
  },
}
